import React from "react";
import PropTypes from "prop-types";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Collapse from "@material-ui/core/Collapse";
import { VIEW_PARAM_KEY } from "../Constants";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import NavigationIcon from "./NavigationIcon";

var _setSelectedViewId;
var _getSelectedViewId;
var _setSelectedView;

function TransitionComponent(props) {
  return <Collapse {...props} />;
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
});

function renderEntries({ entries }) {
  return Object.keys(entries).map((key) => {
    let thisEntry = entries[key];
    let query = new URLSearchParams(window.location.search);
    var selectedId = query.get(VIEW_PARAM_KEY);
    if (selectedId === thisEntry.id) {
      if (thisEntry.id !== _getSelectedViewId()) {
        _setSelectedViewId(thisEntry.id);
        _setSelectedView(thisEntry.content);
      }
    }
    return (
      <StyledTreeItem
        nodeId={thisEntry.id}
        key={thisEntry.id}
        label={<span style={{ whiteSpace: "nowrap" }}>{thisEntry.title}</span>}
        endIcon={thisEntry.endIcon}
        onLabelClick={() => {
          window.history.replaceState(
            {},
            null,
            `/?${VIEW_PARAM_KEY}=${thisEntry.id}`
          );
          _setSelectedView(thisEntry.content);
        }}
      >
        {thisEntry.entries == null
          ? null
          : renderEntries({ entries: thisEntry.entries })}
      </StyledTreeItem>
    );
  });
}

export default function CustomizedTreeView({
  setSelectedViewId,
  selectedViewId,
  setSelectedView,
  defaultSelected,
  entries,
}) {
  const classes = useStyles();
  _setSelectedViewId = setSelectedViewId;
  _getSelectedViewId = () => selectedViewId;
  _setSelectedView = setSelectedView;
  return (
    <TreeView
      className={classes.root}
      defaultExpanded={Object.keys(entries).map((key) => entries[key].id)}
      defaultCollapseIcon={<NavigationIcon type={ExpandMoreRoundedIcon} />}
      defaultExpandIcon={
        <NavigationIcon type={KeyboardArrowRightRoundedIcon} />
      }
      defaultEndIcon={<NavigationIcon type={DescriptionRoundedIcon} />}
      defaultSelected={defaultSelected}
    >
      {renderEntries({ entries: entries })}
    </TreeView>
  );
}
