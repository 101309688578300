import React from "react";
export default function MtArnigo() {
  return (
    <>
      <h2>The Battle for Mt. Arnigo</h2>
      <h3>
        How we lost about about one-half of K Company (Reginald's Co.) 160 men
        to 80 men.
      </h3>
      <p>
        Mt Arnigo. Italy. It is part of the Apennine Mountains. There is a
        highway running north and south between Florence (on the <b>Arno</b>{" "}
        River) and the city of Bologna (home town of Guglielmo Marconi 1874-1937
        inventor of the wireless telegraph). Mt. Arnigo is about 8 miles south
        of Bologna and 2 miles east of the road between Bologna and Florence.
      </p>
      <ol>
        <li>
          <u>Sun night, April 8th, 1945</u>
          <p>
            Rode in trucks with their headlights <b>off</b> for the last few
            miles... then we walked about one half miles and stopped for the
            night.
          </p>
        </li>
        <li>
          <u>Mon morning April 9th</u>
          <p>
            We could see that we were on a plateau. About two blocks in front of
            us there was a cliff leading down into a valley. From the bottom of
            the valley there was a huge mountain shaped like a flattened ice
            cream cone. The "cone" was upside down. The "front" line was the
            cliff before us. Germans were in the valley and all over the mount
            which was called Mt. Arnigo. In our battalion there were three
            Companies: K Co., L Co., and M Co. We were told that our three
            companies were going to play King of The Mountain and take it away
            from the Germans. The battle plan was then explained to us. Our
            sergeant drew a map in the dirt (see map on next page). When it got
            dark on Tuesday, K Co. (my Co.) was going to go down the cliff and
            capture the town. L Co. &amp; M Co. were going to stay on the
            plateau. The next night the engineers were going to come up to where
            we were and repair the bridge that had been destroyed. The next
            night our tanks would come up to the bridge and cross it. L Co and M
            Co. would come down and meet the tanks and together go up Mt Arnigo.
            We were told that the town at the bottom of the cliff consisted of a
            three story hotel in the center of the town with two or three houses
            on the left of the hotel and two or three houses on the right side
            of the hotel. My platoon (about 40 soldiers) was to capture the
            hotel and any buildings nearby. Another one of our platoons was to
            go along the road to the left and the other platoon to the right to
            prevent any Germans from getting in the town after we had captured
            it. We were reminded battles did not always go as planned and there
            was the possibility that some of us might be captured by the
            Germans. We were asked to destroy any addresses we might have with
            us. The Germans sometimes contacted addresses of people they found
            on your possession with stories to make them worry. We were also
            reminded that sometimes some of us did not care about battles and
            they shot a bullet in their foot rather than go along with the
            company. We were told that everyone would be needed and that if
            anyone shot themselves in the foot they would be court-martialed.
          </p>
        </li>
        <li>
          <u>Tues afternoon April 10th</u>
          <p>
            We were told something had "gone wrong" and we would stay on the
            plateau that night.
          </p>
        </li>
        <li>
          <p>
            <u>On both the 11th and 12th</u> we were told the battle was off.
          </p>
        </li>
        <li>
          <u>Fri April 13th</u>
          <p>
            About nine AM, we received word that the President of the US,
            Franklin Roosevelt had died. I was amazed to see the gloom that some
            of the fellows expressed. The battle was not called off. As soon as
            it began getting dark, we started down the cliff. Before I got to
            the bottom three fellows shot themselves in the foot. This took six
            Medics to help them. It was dark by the time we reached the bottom
            of the cliff. We found ourselves on what appeared to be a portion of
            the dirt road. We were walking single file, then the fellow in front
            of me turned around and said as quietly as possible, "Turn around,
            something's gone wrong." For a minute we had a traffic jam. In a few
            minutes, the fellow in front of me said, "It's OK. Let’s go again."
            After we had moved only about fifty feet, the fellow in front of me
            stopped and motioned for me to stop and to get down on the ground
            resting flat on my stomach. In a few moments we were all on the
            ground, waiting for the next move.
          </p>
          <p>
            The next move was a fireworks display that was outstanding. In an
            instant an area about 135 feet &times; 135 feet was full of
            exploding shells. I saw red hot fragments of shells twirling through
            the air. The ground we were resting on was shaking like an
            earthquake. There was a slight breeze that night and soon the breeze
            was full of burnt powder odor. After about five minutes of exploding
            shells, the barrage stopped. All of the lights went out at the same
            moment. It was a masterpiece of timing. The fellow in front of me
            got up and started walking. He walked to the river and walked into
            the water. After walking a short block, I saw our lieutenant holding
            his finger to his mouth to be very quiet and pointing up to the side
            of the river, we saw that we were at the hotel. The hotel was made
            of rocks. The top two floors had been broken into a gigantic pile of
            rocks resting atop a room that had a window and a door area opening
            unto the river. This river was about 18 feet wide and the water was
            about 7 inches deep. Our lieutenant took one man and climbed up the
            bank and disappeared into the room. It was quiet everywhere. The
            only noise we heard was the gurgling of the water. After about 5
            minutes the two men came out of the room and came down into the
            water again. They told us they had rubbed their hand over all the
            wall and their feet over all the floor they reported it was safe for
            all of us to go into that room.
          </p>
          <p>
            Once inside we could see three houses on the other side of the
            river. The lieutenant told two men to cross the river and walk
            towards the first house. He said, "We'll cover you from here." The
            men had walked about 30 feet towards the house when they stepped on
            a shu mine. They both began screaming with pain. Men were sent to
            get them into the room with us. They were given a shot of pain
            killer. This took about 20 minutes to begin to take effect. The
            lieutenant said, "I can’t tell how serious your wounds are because I
            can't see. I then told the lieutenant that I know we are not
            permitted to have flashlights, but I have made one out of a small
            empty match box. To make certain I never accidentally turn on any
            light, I have the batteries in a separate pocket from the flashlight
            itself. If he would like to see how bad are the wounds we could get
            him and the two men in a corner of the room and hold some blankets
            over them. I could put the match box together and pass it to him. He
            agreed. After examining them (and not a trace of light showed). He
            told the fellow who was screaming the loudest that his wounds were
            minor compared to the other soldier. He ordered the loudest fellow
            to get in the river with the other for him to help him to an aid
            station that was three or four blocks down the river. They got into
            the river and we never saw either of them again. The lieutenant
            said, "Let's try the road, it appears to go to the front side of the
            houses." The lieutenant and three or four men (I was one of them)
            then went around to the front of the other side and walked along the
            road. We found the men of the platoon who were to watch for Germans
            on the west side had gone directly to their assignment, had captured
            five or six Germans. That was an eerie experience. They had taken
            off their helmets and were standing very, very quietly with their
            hands resting on top of their heads. Our Lieutenant conferred with
            the officer of the other platoon and decided to have one of these
            Germans take us back to where our two men had stepped on a mine and
            and have him lead us thru the mine field and into each of the three
            houses. The German spelling for these nasty mines was Shu. When you
            stepped on one... it would sometimes blow your foot off right now
            along with your ankle and all you would have is a stub of your leg
            bone exposed. If your leg was still on, your foot would be as red
            jelly, The medics would cut off your shoe, wrap the red jelly and
            take you away... Our two fellows who got hurt at first must have had
            a third condition. I was assigned to take 3 others (Americans) and
            one German and go back and walk thru that mine field. Back we went
            and on a few moments had determined that there were no extra Germans
            hiding in the houses or in the area. By now it was beginning to get
            light. Some of our men had made contact with our platoon on the
            other side of town. There were no Germans and all was well. We went
            to our hotel went to sleep.
          </p>
        </li>
        <li>
          <u>Saturday night April 14th</u>
          <p>
            As soon as it got dark, the Army Engineers began their part of the
            plan. (to build a bridge across the river). They came along the road
            riding a bulldozer and pulling a load of conduit pipes. My platoon
            was assigned to protect the engineers while they built the bridge.
            The bulldozer was very noisy and the Germans began shooting
            artillery as soon as they heard the bulldozer.. The engineers
            stopped until the shooting stopped. The engineers got to our hotel
            about 9:30 PM. They unloaded the conduit into the river lengthwise
            and then with the bulldozer began pushing dirt on the top of the
            conduit. The Germans kept shooting all night. When the shooting got
            too close, the engineers would stop for a little while. About two AM
            we held a meeting with the engineers. The engineers said they had
            the assignment of getting the bridge completed Saturday night, and
            that they still had about two hours to go to complete the bridge.
            They wanted to be far away before the first light of morning came.
            The head engineer proposed that they start the bulldozer and no
            matter how many shells were shot, they would would not turn off the
            bulldozer. We agreed. The bridge was completed about 4.00 AM. The
            Germans continued to shoot at us but no one was hurt. Finally we
            heard the pleasant sound of the bulldozer going away along the road.
            We went back to our hotel and went to sleep.
          </p>
        </li>
        <li>
          <u>Sunday night April 15th</u>
          <p>
            As soon as it got dark, the battle for Mt Arnigo began. Some of our
            tanks came along the road, crossed the river using the bridge that
            had been built the night before and started up the mountain. At the
            same time, all of M company and L company, who had been waiting on
            top of the cliff, came down and started up Mt. Arnigo. It soon
            became apparent that the Germans had no intention of letting anyone
            go up Mt. Arnigo. The Germans knew exactly where their troops were
            and where they were not. They raked the area where they "were not"
            with explosive shells all night long. This included our hotel. Our
            hotel was the safest spot all night long. It was tragic to see the
            members of L and M companies. All night long groups of them would
            run into our hotel to get a little respite from the exploding
            shells. When a voice called out a message such as, "All members of
            third platoon... let's go." I noticed that not all of the third
            platoon went. Fifteen or twenty minutes later these third platoon
            members would slip out the door into the night. Finally, morning
            came and the shooting stopped.
          </p>
        </li>
        <li>
          <u>Monday morning April 16th</u>
          <p>
            A temporary TRUCE was put into effect for the medical corps to
            gather the wounded. We saw two medics carrying a stretcher. Each
            medic had a pole tied to the upper part of his body. And in between
            the poles was a white flag with a large red cross. One set of Medics
            came near our door. They called our attention to the bullet holes in
            their flag. Some Germans observed the Truce by not shooting the
            medics, but they made them nervous by shooting at the flag they
            carried. About nine-thirty that morning we saw a wounded German,
            crawling. He saw us and cried out, "<b>Comrade, comrade</b>." We
            motioned for him to be quiet and to come to us. He did this. When he
            was safely inside our one room hotel we could see his problem. He
            had stepped on a German shu mine and one of his feet and ankle were
            missing. We gave him a shot of morphine, and he was soon asleep.
            Later on some of our medics came our way and they carried him away.
            A little later another wounded German saw us and cried out, "
            <b>Comrade, comrade</b>." We signaled as we had earlier, but for
            some unknown reason he stayed where he was and would not stop crying
            out. After a half an hour of this, we became nervous. We had no
            official communication with anyone for about 24 hours and knowing
            how nasty the Germans could be, we imagined all kinds of things. I
            was standing by the window at the moment and without thinking, I
            said to our Lt. "That German won't come in and won't be quiet, shall
            we shoot him." The Lt said, "Yes." I took off the safety to my rifle
            and aimed it at the German. Just as I was about to pull the trigger
            a strong message came to my mind... The message, "Do not shoot this
            man, it is not right." I began to say this to the Lt., when the
            fellow to my right said, "Well I will." Meanwhile, the German could
            see us aiming our rifles at him and began crawling behind a large
            rock. The bullet was fired just at the moment the German disappeared
            behind the rock. We never knew whether the German was shot or not,
            but he did not cry out anymore and that was what we wanted.
          </p>
          <p>
            Around noon, all of the wounded had been picked up and business got
            back to normal. One of our runners came by with a message from our
            Captain of our Company. We were to go about 300 yards south along
            the river and would be met by the Captain about 30 minutes from
            then. In about thirty minutes, the Captain showed up. He said L
            &amp; M companies had both been destroyed. There was not enough men
            left in either company to be a fighting force. Because we were still
            a fighting force, we were going to take their place. As soon as it
            got dark we were going to walk up Mt Arnigo as far as L &amp; M
            company got and the next morning continue up the mountain. He wanted
            us to study the mountain to remember all we could because at night
            things looked different than they did in day light. He showed us an
            area where some tanks had been and it was safe from mines. After we
            had looked at the mountain we could go back to our places where we
            had been and wait for it to get dark. On our way back to the hotel,
            we came across some of our men who explained we had a problem. There
            was a sniper up on Mt Arnigo. We had sent some men to get him, but
            meanwhile we had a problem. Thus far, the sniper had never shot
            anyone outside of a space about 15 feet wide. The only way to get
            across this space was to make a dash for it one at a time. When it
            came my turn, I made it and then stopped to look myself over. I
            found a crease along the heel of my shoe on my right foot. The
            crease appeared to have been made by a bullet. I said my prayers of
            thankfulness and went back to our hotel. I also thought of our
            hotel. When we first heard of the battle plan everyone felt sorry
            for us because of our assignment. We were in the middle of the
            trouble. As it turned out, we had the best place in the whole area.
          </p>
        </li>
        <li>
          <u>Monday night, April 16th</u>
          <p>
            When it got dark, we started walking slowly up Mt Arnigo. All was
            quiet and no shells were shot at us, and there were no Germans in
            our path. When we had gone a distance about equal to the length of
            three football fields we stopped. In whispered tones we were told,
            "This is as far as any members of L and M had got. As quietly as you
            can, dig a small hole and divide up in twos. Take turns sleeping.
            Always have one of the two of you awake." The rest of the night was
            peaceful... no shooting.
          </p>
        </li>
        <li>
          <u>Tuesday morning April 17th</u>
          <p>
            This was a beautiful morning... everything was still quiet. We ate
            breakfast (K rations) and were given the plan of action. We were
            going to walk slowly up the hill. We felt we were being watched by
            the Germans, so it was up to them to start shooting.. We could see
            no Germans. At one point there was a little ravine and we had to go
            single file. I was the third man in the line. The fourth man was the
            soldier who had shot at the wounded German the day before. Just as I
            was stepping out of the ravine there was an explosion in back of me.
            Number four man had stepped on a shu mine. Three of us had stepped
            over the spot where the shu mine was buried and had not stepped on
            it. The number four man still had his foot on but it was all like
            red runny jello. The medics cut off his shoe and tied a large
            bandage around his foot and carried him away. We never saw him
            again. On the next ravine we were careful! to step exactly where the
            man in front of us stepped, and we got over polite saying such
            things as, "You go first sir," as we stepped aside a little. But no
            one wanted to be ahead so we just followed along. We grew more tense
            as we went slowly up. And then the shooting started. The Germans
            threw everything in the book at us. Some of our men began to be hit
            and fell to the ground. Here I need to digress for a moment to tell
            you of an experience I had at Camp Haan (near Riverside Calif.) in
            the summer of 1943. It seems that one of the Army regulations says
            that all soldiers must take Basic Training. I had missed this
            experience because I had been taken directly from the induction
            center to work in the finance office at Camp Haan. It was discovered
            that 8 or 9 of us had missed this basic training. To correct this
            matter a training sergeant was obtained from a training cadre. We
            stayed at our bunks for half a day for the next two weeks while the
            sergeant read the soldiers' handbook to us. This handbook is very
            similar to the Boy Scout manual. I remember a paragraph on "How to
            cross an open field when the enemy is shooting at you." It said,
            "Never try to make a run for it in a straight line... instead do
            something to divert the enemy from getting a bead on you, such as
            stopping quickly and running a few steps in a different direction,
            etc. This is the end of the digression. Now to continue on with the
            story. At the very moment when the shooting started, an unseen
            influence (my guardian angel) took over and told me what to do. It
            seemed that I was back in Camp Haan and the sergeant was talking to
            me. He said such things as, "Now fall down to the left and roll over
            once... now go forward 4 or 5 steps, then stand still for a
            moment... now run 5 or 6 steps a little to the right, etc. I
            followed the instructions exactly. The shooting lasted about five
            minutes, then stopped as abruptly as it had started. We immediately
            started to dig a hole for protection. At this moment our Lt. sensed
            our fear and jokingly took from his jacket a copy of the Stars &amp;
            Stripes newspaper and said, "Let me read to you fellows a story." He
            had just started to read, when his right arm dropped down. A German
            bullet had gone threw his arm. He had received what we called a
            Million Dollar wound... serious enough to be sent back to the
            States, but not serious enough to impair your living style. We said
            goodbye as the medics helped him down the hill. We soon learned why
            all the shooting had stopped. We had over run the German soldiers'
            position. From two holes directly in front of me, five Germans
            climbed out with their hands high in the air. Myself and another
            fellow were assigned to take these prisoners down the hill and turn
            them over to the officer who receives prisoners. Before we started
            down the hill we took a moment to look around to see if there might
            be a better path other than the one we took in coming up the
            mountain. We noticed that we were about 100 feet from the very top
            of Mt. Arnigo and off to our left side there was a large valley with
            several tank tracks in it. We decided to go get into a tank tread
            because it would not have any shu mines in it. I went first,
            followed by the five Germans who held their hands on top of their
            heads. We had only walked about 300 feet when we saw some German
            artillery shells exploding in the lower part of the valley. The
            explosions were following a pattern that went across the valley left
            to right with a shell exploding about every two hundred feet from
            each other. Then the explosions went up about two hundred feet and
            threw in another row of explosions. We estimated where we would have
            to be to avoid getting ourselves hurt. By the time the explosions
            were getting close we estimated very closely where we would have to
            be to avoid getting hurt. Fortunately for us, the fellows who were
            doing the shooting did not change their pattern and the closest
            shell exploded about 300 feet from us. We continued down the
            mountain, and found the person who received our prisoners. We were
            instructed to go over to some caves and ask for an officer whose
            name was told us. We found the caves and the officer, who asked us
            to stand by for an hour or so, that he might have an assignment for
            us.
          </p>
        </li>
        <li>
          <u>Tuesday afternoon April 17th</u>
          <p>
            About two o'clock I was informed that I was needed to guide a group
            of Signal corps men up the mountain to K Company's Captain. Up to
            now the only means of communication was by walkie talkies They had
            to speak in code, because the Germans also had walkie talkies. I
            remembered I happened to be near the Captain when we were about half
            way up Mt. Arnigo and heard him say, "This is Little Red Riding
            hood. I am at Grandmother’s house and anxious to proceed." I was
            told to go introduce myself to the Signal Corps men and let them
            know I was going to be their guide when it got dark. There was about
            16 of them. They were spending the afternoon in the cave they were
            now in and would depart from there. I went outside to study the
            mountain and visualize what it might look like when it was dark. The
            more I looked at that mountain, the more I did not want to go up. I
            thought of different things I could do to to injure myself. There
            was lots of shrapnel available, so I decided to pound some shrapnel
            into my leg. The shrapnel would need to be hot because all exploding
            shells are very hot. I picked up some cold shrapnel to try it out.
            Then I put my gloves on and waited for a shell to come in. I did not
            have to wait more than 5 minutes and a shell would explode that
            would be no farther away than 300 feet. I did not have a hammer to
            pound the shrapnel thru my pants and into my leg so I had to use a
            rock. I picked up a piece of hot shrapnel and started pounding with
            the rock. I discovered this was going to be difficult. Even if I had
            a hammer, it was going to take an awful lot of force to pound the
            shrapnel into my leg. After almost one hour I had tried pounding
            four pieces of shrapnel into my leg to no avail. One of the Signal
            Corps men called to me to get inside the cave with them or I might
            get hurt. I did not tell him that by now I was hoping I would get
            hurt. After another hour I gave up. All I had accomplished was
            creating two sore legs and all the shells that came in missed me. So
            I gave up and went inside the cave and waited for it to get dark.
          </p>
        </li>
        <li>
          <u>Tuesday night April 17th</u>
          <p>
            We left the security of the cave and started up Mt. Arnigo. After
            about ten minutes travel, I knew I was lost. As quietly as possible
            I told them to back track. Then we started again. This time I
            located the correct gully and we were on our way. As we walked, the
            men unwound the telephone line that was connected to the telephone
            system that was in the cave we had departed from. We came to the
            spot where #4 man had stepped on a shu mine. Those who were
            following me were careful where they stepped. Soon we emerged from
            the gullies and were out on a flat plain that sloped upwards. I
            stopped for a minute because I imagined a mine field galore. What
            should I do? I disliked the idea of stepping on a mine. After a bit,
            I decided to tap an area in front of me with the butt of my rifle
            and as I did so I held the rifle at a sloping angle away from me. So
            again we went forward as I slowly tapped out one "safe spot" after
            another. After about 6 or 7 minutes of this slow travel a voice out
            of the dark said, "Halt, who goes there ?" I answered" a wire party,
            we are on our way to K company." The voice said, "One of you advance
            alone and be recognized." I went forward and learned I was heading
            for the center of a large mine field. A safe path thru the mine
            field had been discovered. It was marked with a rope tied to stakes
            that had been pounded into the ground. These soldiers had the
            assignment to show the path to all who came by that night. After we
            exited the mine field, it was just a short time until we found our
            Captain. The Signal Corps fellows connected the telephone to the
            wire and started back down and I went and found my platoon.
          </p>
        </li>
        <li>
          <u>Wednesday morning April 18th</u>
          <p>
            I saw that we were on top of Mt. Arnigo. About every ten to fifteen
            feet a hole had been dug large enough to hold two men. One was a
            rifleman and the other was a machine gunner who stood behind a
            water-cooled machine gun. I was assigned to one of these holes as a
            rifleman. About 9:00 AM I decided to have some breakfast. (a K
            ration). In each box (similar to a box of Cracker Jack) there was
            always a few cigarettes. I was sitting on the bottom of the hole to
            eat. The hole was about four feet deep. I asked the machine gunner
            if he wanted the cigarettes. He nodded yes and leaned down to take
            them from my hand. At the exact moment when his hand touched the
            cigarettes a German shell landed about six inches in front of the
            machine gun and exploded. I was stunned and remember seeing the
            machine gunner flying out of the hole. He was replaced by the
            machine gun that went up in the air and fell down into the hole with
            the barrel pointing at me. The tripod legs of the machine gun kept
            the machine gun from falling on me. The machine gun was full of
            holes and water was dripping out of them. My first reaction was that
            I was dead. I remembered a talk in a sacrament meeting where the
            speaker gave his opinion that the spirit world was very much like
            this world. I thought, "That fellow was right. This world looks just
            like the one I left." Ten or more minutes went by and the shock of
            the explosion began to wear off. I said to myself, "This is not the
            spirit world... surely they would not have blown up machine guns
            pointing at me." I decided that I was still in the world I knew and
            that I had been wounded but could not yet feel pain. I had seen many
            men wounded and for about twenty minutes they did not hurt. To
            locate my "wound" I slowly moved my hand over my body and then held
            my hand in front of me looking for blood. No blood. It was a
            miracle. If we had been standing, the upper part of our body would
            have had about ten holes in it. We would have been dead. When a
            shell landed that close to you, there would not be time to "duck
            down." If the shell had landed about 18 inches farther south it
            would have landed inside our hole and then both the machine gunner
            and I would have had our bodies blown to pieces. As it turned out we
            both were not injured.
          </p>
        </li>
        <li>
          <u>Wednesday afternoon April 18th</u>
          <p>
            Our Captain was using his binoculars to locate any of the enemy. He
            spoke to me and another rifleman, and called our attention to what
            appeared as the opening of a large cave. He said every so often
            someone seems to go in or out of the cave. Pointing to a tree about
            100 feet in "no man’s land," he said, "Crawl out as far as that tree
            and each of you shoot a couple clips of bullets at the opening."
            About 20 or 30 feet out we came across a ditch that was about three
            feet deep and went in the direction of the tree we wanted. We got in
            the ditch (about 1½ feet wide). About 20 feet away, the ditch turned
            and we could not see beyond the turn until we got to the turn. As
            soon as we made the turn we saw two dead Germans lying in the ditch.
            There was a fly on the nose of one of them. We were now faced with a
            management decision.... 1) crawl across the bodies of these men or,
            2) stand up where we could be seen. We chose to crawl across the
            bodies. We got to the area of the tree and fired a bullet... now
            another problem. The ground was very very dry and each time we fired
            a bullet a cloud of dust arose. We could not think of anything to
            stop the dust so we just went ahead and shot at the cave entrance.
            We did not see anyone run in or out of the cave. On the way back, we
            crawled over the two Germans.
          </p>
          <p>
            It may interest you to know this was the only time I fired my rifle
            while on the front lines.
          </p>
          <p>Wed night was peaceful &amp; quiet. Just a few shells came in.</p>
        </li>
        <li>
          <u>Thursday morning, April 19th</u>
          <p>
            This was a sunny and quiet morning. I decided it would be safe to
            warm the can of meat that was in the K ration. To do this you stood
            the box on end and lit it. As the box burnt lower, you would follow
            down. When you reached the ground all the box was gone and that was
            the end of heating from that box of rations. I was about half way
            down when about 7 or 8 Germans came walking over the hill. They
            wanted to surrender. One of them could speak a little English, and
            said he had been to Chicago. Just before they came over the hill, I
            was happy that no smoke was made as the box burnt. The moment I saw
            them I dropped the ration and reached for my rifle. I can't remember
            whether I ever ate that morning or not.
          </p>
        </li>
        <li>
          <u>Thursday afternoon, April 19th</u>
          <p>
            We were relieved with fresh troops and walked off Mt. Arnigo. We
            were taken to a safe place about four miles away, and our cooks were
            there and fixed a hot supper. They had kept all the mess kits for us
            when we had gone on line because they knew they would not be cooking
            for us until we came back.. That was a sobering event. Everyone had
            located his mess kit and was eating and there was about half of the
            mess kits still sitting Waiting for their owners who never came back
            from the mountain.
          </p>
        </li>
      </ol>
      <p>
        This part of the story took place about 1965. I was attending a Bank of
        America officers dinner meeting and I got to talking war stories with
        the branch manager who was sitting next to me. He also had been in
        Italy. He was in the Signal Corps. The more we talked, the more it
        seemed that he had been one of those signal corp men who followed me up
        the mountain that night. And after a little while we were certain of it.
        He had been with me that night. He then asked if I had heard about what
        happened on the way down. They were following the line to guide them.
        They had gone about half way down when the last man in the line stepped
        on a tank mine and was killed instantly.
      </p>
      <p style={{ textAlign: "center" }}>THE END</p>
    </>
  );
}
