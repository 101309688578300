import React from "react";
export default function PoValley() {
  return (
    <>
      <h2>My Experiences in the Po Valley</h2>
      <p>
        About a week after we left Mt. Arnigo we got on some trucks and drove
        into Bologna. On the outskirts of town we saw a house with a sign out
        front that said this was the house where Marconi lived and conducted his
        experiments of wireless telegraph. We stopped at this house for lunch.
        They have fixed up the front room with displays of some of his works. It
        was very pleasant and interesting. After lunch we drove through to a
        park where we camped for the night. The next morning we gathered around
        an officer who said he would tell us of how things were in Bologna. In
        essence the war was over. Every day whole groups of Germans were
        surrendering.
      </p>
      <p>
        Our duty was going to be to help them surrender. From here on out we
        would divide up into groups and ride on the outside of the tanks that
        had arrived at the park. In the event that we met any Germans who did
        not care to surrender, we would have the support of the tanks to assist
        we infantrymen, so we got aboard the tanks and started north.
      </p>
      <p>
        For the first few days, The only Germans we saw were large groups who
        had already turned themselves in and were marching South. Then one
        afternoon our fortune changed and we ran into some of the enemy who
        wanted to shoot a little more. It all began when we came to a fork in
        the road. There were no signs advising us which way to turn. If there
        had been signs they would have said, "Take the left fork. There is a
        town full of Germans who want to turn over everything. If you take the
        right side of the fork, you will be met by a few Germans who do not want
        to give in yet." Our tank commander did not know which fork to take so
        he took the <b>right</b> one leading right into the Germans who were
        waiting for us. We had gone only a few miles when we started being shot
        at. It was at this time that we wanted to be in the Tank Corps because
        of the protection. There were a lot of open spaces and the tanks quickly
        spread apart so as to make less of a target. We also learned that the
        tank people did not want us to get off any tank until the tank men had
        figured out what their plan of action was going to be. We sat on top of
        the tanks for almost 15 minutes. There was a culvert along the side of
        the road that would give us lots of safety. The tank men chided us for
        being afraid "under fire." Meanwhile, the Germans were improving their
        accuracy in shooting at us. In another 15 minutes, the tank men had
        their plan ready. Off to the right of us about a mile across a large
        field there were 5 or 6 houses. In these houses there must be some of
        the enemy that is directing the firing. We will attack by driving as
        fast as possible into the middle of the houses. We were cautioned to
        hang on tight. If anyone fell off the tank, men would come back and get
        you, but not until all of the Germans had been routed. <b>Here we go</b>
        ... We got safely to the houses, and it turned out they were empty.
        After studying the situation, the tank men decided we should stay where
        we were for the night. Our ride across the field did some good. The
        shooting stopped. That is, until the Germans shot one shell, and only
        one. This shell hit a tank. The tank immediately caught fire. No one was
        hurt, but the tank and everything in it burned. My buddy, John J. Newman
        of St Louis MO. decided to clean his rifle. He discovered the firing pin
        was missing. Had there been any Germans in the house he and I cleared,
        we would have had a problem. During the night, gasoline trucks got us
        ready to go back to that fork in the road. We slept in the culvert. A
        couple of miles down the left fork we found a town full of Germans who
        were waiting to surrender to us.{" "}
        <b>This was the end of the shooting war for me</b>.
      </p>
    </>
  );
}
