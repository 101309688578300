import React from "react";
export default function BronzeStar() {
  return (
    <>
      <code style={{ color: "#2c3e50" }}>
        <div>
          <p style={{ textAlign: "center" }}>
            HEADQUARTERS 91st INFANTRY DIVISION <br /> UNITED STATES ARMY <br />{" "}
            A. P. O. 91
          </p>
          <p style={{ display: "flex", justifyContent: "space-between" }}>
            <span>AG 200.6 18</span> <span>July 1945.</span>
          </p>
          <table>
            <tr>
              <td>SUBJECT:</td>
              <td>Award of the Bronze Star Medal.</td>
            </tr>
            <tr>
              <td style={{ verticalAlign: "top" }}>TO:</td>
              <td>
                Technician Fifth Grade Alvin R. Carlson, 39567410, Infantry,
                United States Army.
              </td>
            </tr>
          </table>
          <p>
            Under the provisions of Army Regulations 600-45, as amended, you are
            awarded the Bronze Star Medal for meritorious service in combat.
          </p>
          <p>CITATION:</p>
          <p>
            "Alvin R. Carlson, (39567410), Technician Fifth Grade, Infantry,
            United States Army. For meritorious service in combat from 15
            January 1945, to 2 May 1945, in North Italy. On 13 April 1945, the
            rifle platoon in which Technician Fifth Grade CARLSON is a member,
            was one of the leading platoons in the assault resulting in the
            break through into the Po Valley. During this attack, the fire from
            all types of enemy weapons was intense, and resistance was
            fanatical. When two men were wounded, Technician Fifth Grade CARLSON
            exposed himself to the intense enemy fire by administering first aid
            to the wounded men. He further exposed himself until he could
            evacuate them to a safe position from which they could later be
            evacuated by a litter team. At all times, Technician Fifth Grade
            CARLSON has displayed a high degree of combat skill, and his
            aggressiveness has proven to be an incentive to the men in his
            platoon. Technician Fifth Grade CARLSON exemplifies the type or
            courage and initiative that are characteristic of the high
            traditions of the Infantry and the Army of the United States.
            Entered military service from Los Angeles, California."
          </p>
          <p style={{ textAlign: "center" }}>
            (signed) <br /> R. E. S. WILLIAMSON <br /> Brigadier General, U. S.
            Army
            <br />
            Commanding
          </p>
        </div>
      </code>
    </>
  );
}
