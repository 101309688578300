import React, { useState } from "react";
import { VIEW_PARAM_KEY } from "./Constants";
import CustomizedTreeView from "./components/CustomizedTreeView";
import BronzeStar from "./stories/wwii/BronzeStar";
import CloseCalls from "./stories/wwii/CloseCalls";
import Intro from "./stories/wwii/Intro";
import MtArnigo from "./stories/wwii/MtArnigo";
import PoValley from "./stories/wwii/PoValley";
import ListOfEvents from "./stories/listOfEvents/ListOfEvents";
import { useMediaQuery } from "react-responsive";
import "./App.css";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import NavigationIcon from "./components/NavigationIcon";

function App() {
  var navData = {
    home: {
      title: "Home",
      id: "home",
      endIcon: <NavigationIcon type={HomeRoundedIcon} />,
    },
    earlyLife: {
      title: "List of Events",
      id: "list-of-events",
      content: <ListOfEvents />,
    },
    warStories: {
      title: "War Stories",
      id: "war-stories",
      content: (
        <div
          style={{
            textAlign: "center",
            height: "50vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ flexGrow: 1 }} />
          <div style={{ flexGrow: 0 }}>
            <h1>REG CARLSON’S WAR STORIES</h1>
            <h2>Written by Alvin Reginald Carlson (1920-1998)</h2>
          </div>
          <div style={{ flexGrow: 1 }} />
        </div>
      ),
      entries: {
        intro: {
          title: "Intro",
          id: "war-stories-intro",
          content: <Intro />,
        },
        bronzeStarStory: {
          title: "Bronze Star Medal",
          id: "bronze-star",
          content: <BronzeStar />,
        },
        closeCalls: {
          title: "Close Calls",
          id: "close-calls",
          content: <CloseCalls />,
        },
        mtArnigo: {
          title: "Battle of Mt. Arnigo",
          id: "mount-arnigo",
          content: <MtArnigo />,
        },
        poValley: {
          title: "The Po Valley",
          id: "po-valley",
          content: <PoValley />,
        },
      },
    },
  };
  const [selectedViewId, setSelectedViewId] = useState("");
  const [selectedView, setSelectedView] = useState(null);
  const [sideNavProps, setSideNavProps] = useState({
    width: "0px",
    sideBorder: "0px",
  });
  const showSideNav = () => {
    setSideNavProps({
      width: "250px",
      sideBorder: "1px solid gray",
    });
  };
  const hideSideNav = () => {
    setSideNavProps({
      width: "0px",
      sideBorder: "0px",
    });
  };
  let query = new URLSearchParams(window.location.search);
  var selectedId = query.get(VIEW_PARAM_KEY);

  const backgroundImageStyle = {
    // height: "100vh",
    backgroundImage: "url(./pictures/PogsWarPortrait.jpg)",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
  };
  const isSmallScreen = useMediaQuery({ maxWidth: 991 });
  const renderContent = (menu, content) => (
    <div
      style={{
        height: "100vh",
        color: "#2c3e50",
        fontSize: "16px",
        lineHeight: "1.75",
      }}
    >
      {isSmallScreen ? (
        <>
          <Navbar>
            <Navbar.Brand onClick={showSideNav}>
              <MenuRoundedIcon />
            </Navbar.Brand>
          </Navbar>
          <div
            style={Object.assign(
              {
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              },
              selectedView ? {} : backgroundImageStyle
            )}
          >
            <div>{content}</div>
            <div
              style={{
                height: "100%",
                width: sideNavProps.width,
                position: "fixed",
                zIndex: 1,
                top: 0,
                left: 0,
                backgroundColor: "white",
                overflowX: "hidden",
                transition: "0.5s",
              }}
            >
              <div
                style={{
                  height: "100%",
                  borderRight: sideNavProps.sideBorder,
                }}
              >
                <Navbar>
                  <span style={{ width: "100%" }} />
                  <Navbar.Brand onClick={hideSideNav}>
                    <CloseRoundedIcon />
                  </Navbar.Brand>
                </Navbar>
                <div
                  style={{
                    paddingLeft: "1em",
                    paddingTop: "1em",
                  }}
                >
                  {menu}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{
              paddingTop: "2em",
              backgroundColor: "white",
              display: "flex",
              paddingLeft: "1.5em",
              minWidth: "16.4rem",
              borderRight: "1px solid #eaecef",
              marginRight: -1,
              overflowY: "auto",
              fontSize: "15px",
            }}
          >
            {menu}
          </div>
          <div
            style={Object.assign(
              {
                display: "flex",
                flexGrow: 1,
                overflow: "scroll",
                overflowX: "hidden",
              },
              selectedView ? {} : backgroundImageStyle
            )}
          >
            {content}
          </div>
        </div>
      )}
    </div>
  );
  return renderContent(
    <CustomizedTreeView
      entries={navData}
      defaultSelected={selectedId}
      setSelectedViewId={setSelectedViewId}
      selectedViewId={selectedViewId}
      setSelectedView={(value) => {
        setSelectedView(value);
        hideSideNav();
      }}
      selectedView={selectedView}
    />,
    <Container>
      <Row>
        <Col md={{ offset: 2, span: 8 }}>
          <div style={{ marginTop: "2em", marginBottom: "2em" }}>
            {selectedView ?? (
              <div
                style={{
                  color: "white",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "80vh",
                }}
              >
                <div style={{ display: "flex", flexGrow: 1 }} />
                <h1>
                  {/* <code style={{ color: "white" }}>Alvin Reginald Carlson</code> */}
                </h1>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default App;
