import React from "react";
export default function ListOfEvents() {
  return (
    <div>
      <ol>
        <li>
          <p>
            Born at home in a house on Capitol Hill, Salt Lake City, Utah. The
            house is about four blocks west of the Utah State Capitol Building.
          </p>
        </li>
        <li>
          <p>
            When about two years old Dad &amp; Mom moved to Hollywood,
            California. They had come to So. Calif. during a honeymoon trip.
            They were married in the Salt Lake Temple.
          </p>
        </li>
        <li>
          <p>
            We lived in a house near 60th &amp; Western Ave (east of Western).
            There was an older boy living in the neighborhood (a neighbor) who
            sometimes would take me and my brother Stanley to the show... The
            theater was just around the corner from our house. The movies in
            these days were "silent." After a scene or two the words the actors
            had spoken would show on the screen. I remember it was fun to guess
            in advance what the actors had said and see if you had guessed
            correctly when the words were displayed. About this time as I recall
            a few talking movies began to appear. When we went to the movies we
            asked, "Is it a talking movie??"
          </p>
        </li>
        <li>
          <p>
            When I was in the third grade (approx.) Dad bought a new house. We
            called it our White House. It was painted white. It was about three
            blocks west of Western Ave on either 60th or 65th St in Los Angeles.
          </p>
        </li>
        <li>
          <p>
            One evening while we lived in the White House Dad came home in a
            brand new car. An{" "}
            <a href="https://en.wikipedia.org/wiki/Essex_(automobile)">Essex</a>
            . I think this car had a starter in it. It seems the cars we had
            before this had to be started by hand with a crank.
          </p>
        </li>
        <li>
          <p>
            For several years we spent summer time at Grandpa &amp; Grandma
            Mann's in Bountiful, Utah. During these summers we would sometimes
            spend a week or two at Aunt Vera White's. Roy and Vera White lived
            in Farmington, Utah. They owned 160 acres of land. Uncle Roy always
            had a "gentle" horse for us kids to ride, usually bareback. To get
            to Utah we would usually "head across the desert" about 5:30 PM. Dad
            would drive all night. Sometimes the next morning we would be at
            Cedar City. Dad would then take the train back to Los Angeles and
            Mom would drive on into Bountiful. At the end of the summer, Mom
            would start driving home. I can't remember if she drove all the way
            home, or if Dad came as far as Cedar City to meet us. I can remember
            Mom stopping beside the road and heating water in a{" "}
            <a href="https://en.wikipedia.org/wiki/Sterno">Sterno</a> Canned
            Heat portable one burner stove to make hot baby food for her babies
            she had along. I guess Allen and or Gary.
          </p>
        </li>
        <li>
          <p>
            For some reason Dad could not make payments on the White House and
            we had to move into a rented house. The morning we moved the
            "sheriff" or some other such officer came and stood while we moved
            out of the White House. I was sort of scared. The rented house was
            just across the street from the grammar school I had been going to,
            so it was actually easier for me to get to school. The move to this
            house was the beginning of the end for my father. He juggled the
            books at his work and was arrested and put on a county farm
            detention camp after a trial. Soon after his arrest we moved to 85th
            Street about two blocks east of Central Avenue. A food delivery
            truck from the county welfare dept brought food to our house about
            once a week. Mom was embarrassed because she had belonged to a "
            <a href="https://en.wikipedia.org/wiki/Contract_bridge">
              bridge club
            </a>
            " of sisters in the Church. It seems they would take turns meeting
            at one house once a month to play bridge. I remember they always
            served fancy lunches and we got to eat all kinds of nice things...
            after we moved to 85th Street Mom stopped playing bridge with her
            "club."
          </p>
          <p>
            When I was ten years old Mom &amp; Dad decided it was time to get
            Stan &amp; myself baptized. Stan was eight. Stan and I got baptized
            on the same day, at Adams Ward in Los Angeles. I can remember not
            wanting to get out of the car when we arrived at the ward. I was
            afraid of going under the water I think.
          </p>
          <p>
            After we had lived in the 85th street house for about 6 months, Dad
            came home from the detention camp. It seems he could not find work
            and as near as I can remember he was usually drunk after that time.
            He violated some of the conditions of his parole and was sent to San
            Quentin Prison for 6 months to a year. I remember Mom receiving a
            card from Dad at the prison. It was hand made and made me feel sad
            for my father. Our main source of income was LA county relief. I
            remember how embarrassed I was when the county relief people would
            come to our house to "see how things were." They did this about once
            a month. When I was eleven we moved to a house "in the rear of
            another house." This "rear" house was on 85th St between Main &amp;
            Broadway in Los Angeles. The people that lived in the front house
            were members of the Church and very active. They were the Brigham
            Robinson family. I remember several "friendly men" from Matthews
            Ward visiting us. I was made deacon while I was still eleven years
            old. It was fun being a deacon. The ward was about a mile away.
            Usually we walked to church but sometimes we rode with the
            Robinsons. We did not have a car that I can remember.
          </p>
        </li>
        <li>
          <p>
            For the next several years Dad was in and out of either jail or
            prison. He was always broke and usually drunk. I can remember
            hearing him pull the cork out of a bottle of wine in the night.
            Finally about 1936 Mom decided to get a divorce. Dad got out of jail
            and was sent to Salt Lake City to his folks house. I only saw him
            twice after this as I recall. Mom had a car and we had driven to
            Bountiful, Utah. Dad came to Grandma Mann's house to see us... he
            was an awful sight. His hair had not been cut in months and he
            appeared dirty and dishevelled. About a week later he came to see us
            again. He had gotten his hair cut and had on clean clothes. I think
            this was the last time I saw him.
          </p>
        </li>
        <li>
          <p>
            I graduated from John C Fremont High School in Los Angeles in June
            1938. A few months before this the county relief people visited us
            and told us that after June 1938 the county would no longer assist
            us. They expected me to get a job and support my mother and three
            younger brothers... and this is what I did.
          </p>
        </li>
        <li>
          <p>
            A brother in our ward or stake worked for the state employment
            office. He guided me to my first job... painting houses. Most of the
            houses were in the Leimert Park area of Los Angeles (Crenshaw and
            54th Street). I rode the street car to this general area each
            morning and would be met at a designated time by my boss and driven
            to whatever job site we would work at that day. I had two bosses, a
            German and a jew. The German was the painter and the jew was the
            hanger. They were always discussing the jew German problem
            developing in Germany. I learned how to paint very well. One night
            as I was coming home on the street car I saw a fellow graduate of
            Summer '38 John C Fremont High School. He was all dressed up and did
            not appear to be tired. He told me he was working for the Bank of
            America. A short time later I took a few days off work and went to
            every bank in downtown Los Angeles and asked for a job. About three
            weeks later the Bank of America gave me a job. I started on Dec 22,
            1938 just before Christmas.
          </p>
          <p>
            My job was in the Central Office on 7th St at Kip St in downtown Los
            Angeles. There was a rule in effect at the time that all new
            employees had to work in Central Office for one year. At the end of
            the year they would transfer you to a branch of the bank as close to
            your home as reasonably practical. At the end of 13 months I was
            transferred to Manchester Broadway Branch #320. Most of the work at
            Central Office consisted of sorting checks into sorting racks and
            then adding the amounts of the checks using an adding machine. For
            some reason not known to me I operated an adding machine for only
            about a month. Then I was transferred to the City Cash Collection
            section of Central Office. There were only about 16 to 18 people in
            this dept. The boss was{" "}
            <a href="./sources/RobertCamerlynckCensus1940.jpg">
              Robert Camerlynck
            </a>
            . About 12 of the employees were messengers. I was a messenger. Our
            hours were from 6:00 AM until we had finished our afternoon run.
            Usually about two o'clock. After our morning and afternoon "run" we
            used the adding machine to balance the debits and the credits. I was
            intrigued by these new words, debits and credits... I studied them
            intensely, however I had difficulty understanding why sometimes a
            debit would increase a ledger balance and at other times would
            decrease a ledger balance. When I left Central Office to go to
            Manchester Broadway, they gave me a short farewell "party" and gave
            me a plaque that something to the effect that I was the only person
            who in one year had not been able to completely understand debits
            and credits and in all likelihood I probably would never understand
            them. We all laughed (including me) and away I went to Manchester
            Broadway.
          </p>
          <p>
            I was at Manchester Broadway until I went into the army in March
            1943. My first job at Manchester Broadway was as "batch clerk."
            Later I became a bookkeeper. I always wanted to become a teller, but
            was told I was too young. Each day I would post my books as fast as
            I could and then watch the tellers to learn what they did. No one
            was very cooperative however and it was difficult to pick it up.
            During those days the banks were opened Saturday mornings from{" "}
            <strike>9</strike> 10? till noon. One Saturday a lot of tellers
            phoned in sick. Out of a normal complement of ten or so tellers,
            there were about six of them "sick." The chief clerk came to me and
            said, "Reggie, you have been wanting to be a teller for a long time.
            Today you are going to be one. Here's a set of cash. Verify it and
            be ready to open a window when the bank opens." My very first
            customer had a simple transaction. It was a man who wanted to
            deposit 10 or 15 dollars into his savings account. I was so nervous
            I even "teller stamped" my hand as well as the deposit ticket. The
            customer could sense my nervousness and was very kind to me. After
            the bank closed, we balanced our cash and I "balanced." I was
            exhausted and elated. A few weeks after this I was made a teller
            full time... and nothing was said about my being "too young." The
            General Ledger bookkeeper was a teller who as his fore banking hours
            and after banking hours had the assignment of posting the "General
            Ledger." The General Ledger was lots of debits and credits of all
            shapes, sizes and colors. I would complete my regular assignments as
            rapidly as I could and then offer to help the General Ledger
            bookkeeper. Most of the time he was willing to let me help. Then one
            day the General Ledger bookkeeper let it ben known he was going to
            be gone on vacation and there was no one trained to take his place.
            Somehow I got the job although the Chief Clerk was somewhat
            reluctant. During those two weeks I mastered the General Ledger
            completely. About a month after the General Ledger bookkeeper
            returned from his vacation, he was given other duties and I became
            the General Ledger bookkeeper. I could see this created a little
            friction with the former General Ledger bookkeeper and it took many
            months for he and myself to be "friends" with each other. I was a
            regular teller for a year or so and then was promoted to note
            teller. I was a note teller when I went into the Army on March 31,
            1943.
          </p>
          <p>I will write my army experiences as a separate story.</p>
        </li>
      </ol>
    </div>
  );
}
