import React from "react";
export default function CloseCalls() {
  return (
    <>
      <h2>Dangerous War Experiences in Italy, 1945</h2>
      <p>
        These experiences took place in the Apennine mountains between Florence
        and Bologna. If you drive north from Florence towards Bologna about
        three fourths of the way and then make a right turn on little side roads
        for about one to three miles you will be where I was. I was on the front
        lines twice in this area. The first time was for about five weeks. It
        became a "battle of nerves." We had about 40 men in each platoon in K
        Company. There were four platoons. We soon learned that about one man
        per week in each platoon would be killed or injured. We never knew who
        it would be to "get hit next," or how it would happen. The second time I
        was on the the front lines in this area, we attacked the Germans. Our K
        Company was one of the leading companies. This means that when the
        attack started, we went first and went both night and day. This time I
        was on the front lines for exactly one week. We attacked Friday night
        April 13, 1945. The next Thursday we were relieved by fresh troops. In
        that one week we lost about half of K Company who were either killed or
        wounded. With this information as background, I will now give the
        details of the "close calls" I had.
      </p>
      <p>
        <u>During those first five weeks I had four close calls</u>. During this
        time, the newspapers reported that there was little or no activity on
        the front lines in this area.
      </p>
      <p>
        I think it might help you get a feeling of these four close calls if I
        give you some detailed background of our activities at that time... Our
        duties during these five weeks were two in nature, and we were to
        alternate them each day, or rather each night. Our main duty was to be
        "out in the holes" one night, and the next night to "run the Gauntlet"
        to bring up mail, food, and supplies from a supply station about 1/4 th
        mile to the rear. "Out in the holes" meant to tuck a telephone line
        inside your jacket and crawl out towards the Germans and stay there all
        night. We were to give the first warning if the Germans attacked. It was
        bitter cold. We took about an hour to dress for the night. We took off
        our shoes and put on waterproof galoshes. We lined the bottom of the
        galoshes with two or three pieces of cardboard and put on five or six
        pairs of stockings. We wore several shirts and sweaters. The last piece
        of clothing was our regular jacket, but just before this we put on a
        pair of "combat pants." We had one pair of combat pants for each two
        men. On the night we were out in the holes, we wore the combat pants. On
        the night we ran the gauntlet, we wore our regular shoes and clothing.
        The combat pants were an oversized coverall (just like farmers wear)
        with a front bib and shoulder straps up the back. It was made with heavy
        cloth and lined inside with a material that was just like an Army
        blanket. I have stood all night in a hole with an inch or two of water
        in it and the water has frozen and formed a crust of ice and my feet
        were comfortable all night. For military support for us, we had some
        mortars located a block or two behind us. We memorized a set of numbers
        and what they meant. When we wanted the mortars to fire away, we would
        whisper a number in the telephone. For example, the number twelve might
        mean that we wanted mortar fire 100 feet in front of us and 30 feet to
        the right. So much for the background of <b>out in the holes</b>.
      </p>
      <p>
        Now for "Run the Gauntlet." This meant for a group of us to walk from
        the front to a supply station about 1/4th mile to the rear. During our
        walk the Germans would shoot flares up into the sky to illuminate the
        path. The objective was to catch us out in the open and throw a barrage
        of artillery at us. The flares were attached to parachutes and slowly
        floated to earth. We were told that the most effective defense against
        the flares was to immediately hold still and not move until the flare
        had gone out. It was easy on the way to the supply station to "freeze"
        in whatever position you were walking in, but on the way back it was
        sometimes awkward to freeze while carrying cases of hand grenades or
        bullets... The German mortars made a shwoosing sound just before the
        parachute opened and the flare lit up the sky. As I remember, we had a
        few seconds warning to get into as comfortable freeze position as
        possible. Each flare would only take 30 seconds to reach the ground, but
        at the moment, it seemed more like 30 Minutes. And just to liven things
        up a bit, the Germans would sometimes send up several flares one after
        another and the sky would be lit up for several minutes. We soon learned
        these flares were more psychological than dangerous. We always had at
        least one flare while walking along the path but only about one
        artillery shell out of three round trips and never a barrage. Once in
        awhile, a flare would light up while it was in front of you and you
        could see what it might look like to a German. Because the flare was
        slowly falling to earth, the light was moving down and every shadow was
        in motion. To see us, the Germans would have to see our shadow in an
        opposite motion. This experience of Walking the Gauntlet was
        interesting. All of the supplies at our supply station had been carried
        by mules from a larger supply station farther to the rear. The mule
        drivers were civilian Italians. They all wore a large Tyrolean hat with
        a long feather on the hat. They seemed to have no fear of the front
        lines.
      </p>
      <p>
        So much for background. Here now are the details of my four close calls.
      </p>
      <h2>Close Call #1.</h2>
      <h3>I was the target.</h3>
      <p>
        My first close call happened on my first night out in the holes. The
        Germans tried to blow us up with artillery shells. It really was three
        close calls that night. There were three separate barrages aimed at us.
        The first barrage came at us about 10:30; the second about midnight, and
        the third about 3:00 in the morning. It was frightening. With the first
        one I realized that I was the target (or rather my partner and I). We
        always had two men together out in the holes.. There must have been
        about twenty shells in each barrage. Pieces of dirt and rocks fell on
        us. The noise of the shells exploding was enough to make you nervous.
        Except for being scared to death, we were not hurt.
      </p>
      <h2>Close Call #2</h2>
      <h3>It was not my turn in the holes that night.</h3>
      <p>
        About one week later, on the night when it was my turn to run the
        gauntlet, the Germans crawled up to my hole and started throwing hand
        grenades at the two men in the hole. They screamed for help in the
        telephone, but the grenades exploded, injuring both men seriously. Five
        or six men ran out to bring the wounded men to safety. The next day our
        Captain decided this hole was too close to the Germans and he gave
        instructions to move it back about 150 feet. When it got dark, the
        sergeant crawled out with us and picked out a nice hole that was about
        25 feet in front of what was remaining of a tree. There was only two or
        three branches still on the tree. It made you nervous just looking at
        it. I was in this new hole about one week and was then assigned to a
        different hole. The day after the the two men had been injured, I was
        given the combat pants that the soldier had been wearing with me on an
        every other night basis. There were 5 or 6 bloody holes in it. I put the
        pants out in the warm sun for a couple of hours till the blood was dry.
        I then scraped off the blood and wore those pants out in the holes that
        night.
      </p>
      <h2>Close Call #3</h2>
      <h3>Another platoon was selected.</h3>
      <p>
        Someone decided that a "test run" needed to be made by one of our four
        platoons to determine the combat readiness of the Germans. The plan was
        to send one whole platoon including the lieutenant that commanded the
        platoon. They were to run over the top of the hill we were occupying.
        They were supposed to run to a fixed spot, early in the morning, and
        then run back. My platoon was not selected to make this test. The
        morning selected came and the platoon members ran over the hill. Within
        about two minutes everyone of them was dead (except for one single
        solitary man). He had been wounded and as he fell to earth he could see
        that everyone else was either dead or wounded. This one solitary soldier
        "played Dead" all day long. When it got dark, he crawled back to our
        lines and told us what had happened. Two days later we were asked to
        volunteer to go get the lieutenant’s body back. I do not know if anyone
        volunteered. If my platoon had been selected to make that "test," I
        would not be giving you this report.
      </p>
      <h2>Close Call #4</h2>
      <h3>The Spirit saved me.</h3>
      <p>
        One evening, I was by myself in the remains of what must have been a two
        story house. I was getting dressed for a night in the holes. After I was
        dressed and ready to go get my partner I felt inclined to wait a
        moment... I waited about 3 or 4 minutes. In a moment a few artillery
        shells burst about 6 feet directly in front of the door I would have
        come out of. The "door" was an army blanket fastened across the opening
        to the room. The blanket had 4 or 5 holes in it. These holes would have
        been in me had I gone out when I usually did after dressing for the
        night. I felt inclined to wait some more... I waited and another
        artillery barrage came in... I then realized how nasty the Germans were.
        Had the first barrage wounded someone, the second barrage came just
        about the time when the medics would be helping whoever had been hit by
        the first barrage. After this second barrage, I felt it was O. K. to
        leave. I did so and went and got my partner for the night and went out
        to our hole.
      </p>
      <h3>EPILOGUE</h3>
      <p>
        A month or so later, when I was on top of Mt. Arnigo, I came across
        about forty dead Germans whose bodies were arranged in a large circle.
        In the center of the circle, the German rifles were neatly stacked. I
        asked, "What happened here?" The soldier I asked said that one of them
        had made a false move. I wondered if someone was "trading" these forty
        German soldiers for the platoon of Americans that had been killed a
        month or so earlier. Where did all the holes come from? About one in ten
        holes had been dug by someone before us. The other nine had been made{" "}
        <b>instantly</b> by an exploding shell. The holes came in two sizes
        depending upon the size of the shell... <b>large</b>, about six feet
        across and three feet deep... and <b>small</b>, about three feet across
        and eighteen inches deep. These amounts are for "normal soil." The
        reason I did not get killed was because the dirt on top of Mt. Arnigo
        was almost solid clay.
      </p>
      {/* See Mt. Arnigo Wed April 1. */}
    </>
  );
}
