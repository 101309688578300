import React from "react";
export default function Intro() {
  return (
    <div name="intro">
      <p>
        My stories can be classified into two categories:
        <ol>
          <li>
            Events that tell of events that happened while I was on the front
            lines, and
          </li>
          <li>Events that happened other than the front line.</li>
        </ol>
        This paper tells of only events that happened in the group one category.
      </p>
      <p>I was on line three times.</p>
      <p>
        The first time was for about five or six weeks. The second time was for
        five days. The last time was for two days and took place in the Po
        Valley in northern Italy. I have never put the story of this third
        experience in writing until now. The enclosed papers describe all three
        stories. Several months ago I decided to send out copies of the stories
        to my children and my brothers. Because of my Parkinson’s condition I
        mixed up the record of <b>who had what</b>. As near as I can tell some
        you have no copy of anything.
      </p>
      <p>
        To correct this matter, I improved my record keeping, and have put all
        my war stories in writing... and mail a complete set to all of my
        children and brothers. If any body else wants a copy they can see me.
      </p>
      <p>
        Following this introduction there is a copy of the award of the Bronze
        Star medal. The next four pages tell of the happenings during my first
        time when I was <b>on line</b>. The next 12 pages tell of a lot of
        events during my second time on line, followed by one page that tells
        about our Po Valley activities.
      </p>
      <p>
        It's been 50 years since these events took place, yet it seems as though
        it was only a few days ago.
      </p>
    </div>
  );
}
